export const remindOptions = [
    {key: -1, title: '不提醒'},
    {key: 0, title: '日程开始时'},
    {key: 300, title: '5分钟前'},
    {key: 900, title: '15分钟前'},
    {key: 3600, title: '1小时前'},
    {key: 86400, title: '1天前'},
]

export const repeatOptions = [
    {key: -1, title: '不重复'},
    {key: 0, title: '每日'},
    {key: 1, title: '每周'},
    {key: 2, title: '每月'},
    {key: 5, title: '每年'},
    {key: 7, title: '工作日'},
]

export const schItems = [
    {
        key: 'cal_id',
        label: '日历',
        component: 'a-select',
        props: {
            placeholder: '请选择',
            options: []
        },
        rules: [{ required: true, message: '请选择日历', trigger: 'change' }]
    },
    {
        key: 'summary',
        label: '日程标题',
        component: 'a-input',
        props: {
            placeholder: '请输入日程标题',
        },
        rules: [{ required: true, message: '请输入日程标题', trigger: 'blur' }]
    },
    {
        key: 'time',
        label: '起止时间',
        component: 'a-range-picker',
        props: {
            showTime: {format: 'HH:mm'},
            format: 'YYYY-MM-DD HH:mm',
            valueFormat: 'YYYY-MM-DD HH:mm',
            placeholder: ['开始时间', '截止时间']
        },
        rules: [{ required: true, message: '请设置日程起止时间', trigger: 'change' }]
    },
    {
        key: 'description',
        label: '日程描述',
        component: 'a-textarea',
        props: {
            placeholder: '请输入',
            rows: 4
        },
    },
    {
        key: 'remind',
        label: '提醒',
        component: 'a-select',
        props: {
            placeholder: '请选择',
            options: remindOptions
        },
        rules: [{ required: true, message: '请设置日程提醒方式', trigger: 'change' }]
    },
    {
        key: 'repeat',
        label: '重复',
        component: 'a-select',
        props: {
            placeholder: '请选择',
            options: repeatOptions
        },
        rules: [{ required: true, message: '请设置日程重复方式', trigger: 'change' }]
    },
]

export const calendarItems = [
    {
        key: 'organizer',
        label: '组织者',
        component: 'select-book',
        props: {
            type: "user"
        },
        rules: [{ required: true, message: '请选择组织者', trigger: 'change' }]
    },
    {
        key: 'summary',
        label: '日历标题',
        component: 'a-input',
        props: {
            placeholder: '请输入日历标题',
        },
        rules: [{ required: true, message: '请输入日历标题', trigger: 'blur' }]
    },
    {
        key: 'color',
        label: '颜色',
        component: 'colorPicker',
        rules: [{ required: true, message: '请选择日历主题色', trigger: 'blur' }]
    },
    {
        key: 'userList',
        label: '共享范围',
        component: 'select-book',
        props: {
            multiple: true,
            type: "book"
        },
    },
    {
        key: 'description',
        label: '日历描述',
        component: 'a-textarea',
        props: {
            placeholder: '请输入',
            rows: 4
        },
    },
]
