<template>
    <div class="content md temple-calendar">
        <a-tabs :active-key="key" tab-position="left" style="height: 100%" @tabClick="handleClick">
            <a-tab-pane :key="1" tab="日历总览">
                <div class="tab-pane-box">
                    <a-button type="primary" class="sch-add" @click="addSch">添加日程</a-button>
                    <calendar lunar @change="dateChange">
                        <template v-slot:date="{day}">
                            <div class="date-sch" v-if="day.inMonth && schMap[day.dateStr]">
                                <a-popover trigger="hover" v-for="sch in schMap[day.dateStr]" :key="sch.schedule_id">
                                    <div class="sch-name" slot="title">{{sch.summary}}</div>
                                    <div class="sch-ctx" slot="content">
                                        <div class="calendar-info">
                                            <div class="calendar-shares">
                                                <div class="share-title">重复：</div>
                                                <div class="share-title">{{sch.repeatText}}</div>
                                            </div>
                                            <div class="calendar-shares">
                                                <div class="share-title">提醒：</div>
                                                <div class="share-title">{{sch.remindText}}</div>
                                            </div>
                                        </div>
                                        <div class="calendar-operate">
                                            <div class="calendar-btn" @click="editSch(sch)">编辑</div>
                                            <div class="calendar-btn calendar-del" @click="delSch(sch)">取消</div>
                                        </div>
                                    </div>
                                    <div class="date-sch-item" :style="{backgroundColor: sch.showColor}">{{sch.summary}}</div>
                                </a-popover>
                            </div>
                        </template>
                    </calendar>
                    <div class="calendar-loading" v-show="loading">
                        <a-spin tip="加载中...">
                            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                        </a-spin>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="日历管理">
                <div class="tab-pane-box">
                    <div class="calendar-list-area">
                        <div class="calendar-top">
                            <div class="calendar-top-title">日历列表</div>
                            <div class="calendar-top-btn">
                                <a-button type="primary" @click="addCalendar">添加日历</a-button>
                            </div>
                        </div>
                        <div class="calendar-list" v-if="list && list.length > 0">
                            <div class="calendar-item" v-for="c in list" :key="c.cal_id">
                                <div class="calendar-ctx">
                                    <div class="calendar-info">
                                        <div class="calendar-name" @click="showDetail(c)">{{c.summary}}</div>
<!--                                        <div class="calendar-desc ellipsis">{{c.description}}</div>-->
                                        <div class="calendar-shares ellipsis">
                                            <div class="share-title">组织者：</div>
                                            <open-data class="share-user-name" type="userName" :openid="c.organizer"/>
                                        </div>
                                        <div class="calendar-shares">
                                            <div class="share-title">共享范围：</div>
                                            <div class="calendar-share-list ellipsis" v-if="c.userList && c.userList.length > 0">
                                                <div class="book-item" v-for="(b, i) in c.userList" :key="i">
                                                    <a-icon class="book-icon" :type="b.type == 'user' ? 'user' : 'folder'"/>
                                                    <open-data class="book-name" :type="`${b.type}Name`" :openid="b.id"/>
                                                </div>
                                            </div>
                                            <span v-else>无</span>
                                        </div>
                                    </div>
                                    <div class="calendar-operate">
                                        <div class="calendar-btn" @click="editCalendar(c)">编辑</div>
                                        <div class="calendar-btn calendar-del" @click="delCalendar(c)">删除</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a-empty :image="emptyImage" description="暂无日历" v-else-if="list"/>
                    </div>
                    <div class="calendar-detail" v-if="calendar">
                        <div class="calendar-top">
                            <a-button class="calendar-top-back" @click="calendar = null"><a-icon type="left"/>返回</a-button>
                            <div class="calendar-top-title">日历详情</div>
                            <a-space class="calendar-top-btn">
                                <a-button type="primary" @click="editCalendar(calendar)">编辑</a-button>
                                <a-button type="danger" @click="delCalendar(calendar)">删除</a-button>
                            </a-space>
                        </div>
                        <div class="calendar-detail-info">
                            <div class="calendar-detail-title">
                                <div class="calendar-detail-name">{{calendar.summary}}</div>
                                <div class="calendar-shares">
                                    <div class="share-title">组织者：</div>
                                    <open-data class="share-user-name" type="userName" :openid="calendar.organizer"/>
                                </div>
                            </div>
                            <div class="calendar-desc">{{calendar.description}}</div>
                            <div class="calendar-shares">
                                <div class="share-title">共享范围：</div>
                                <div class="calendar-share-list" v-if="calendar.userList && calendar.userList.length > 0">
                                    <div class="book-item" v-for="(b, i) in calendar.userList" :key="i">
                                        <a-icon class="book-icon" :type="b.type == 'user' ? 'user' : 'folder'"/>
                                        <open-data class="book-name" :type="`${b.type}Name`" :openid="b.id"/>
                                    </div>
                                </div>
                                <span v-else>无</span>
                            </div>
                        </div>
                        <div class="calendar-sch">
                            <div class="calendar-sch-title">日程列表</div>
                            <div class="calendar-list" v-if="calendar.schedule && calendar.schedule.length > 0">
                                <div class="calendar-item" v-for="sch in calendar.schedule" :key="sch.schedule_id">
                                    <div class="calendar-ctx">
                                        <div class="calendar-info">
                                            <div class="calendar-name">{{sch.summary}}</div>
                                            <div class="calendar-shares">
                                                <div class="share-title">重复：</div>
                                                <div class="share-title">{{sch.repeatText}}</div>
                                            </div>
                                            <div class="calendar-shares">
                                                <div class="share-title">提醒：</div>
                                                <div class="share-title">{{sch.remindText}}</div>
                                            </div>
                                        </div>
                                        <div class="calendar-operate">
                                            <div class="calendar-btn" @click="editSch(sch)">编辑</div>
                                            <div class="calendar-btn calendar-del" @click="delSch(sch)">取消</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>
        <a-modal
            :title="`${form.cal_id || form.schedule_id ? '编辑' : '添加'}${title}`"
            :width="600"
            @ok="addConfirm"
            v-model="visible">
            <form-area
                ref="calendarForm"
                class="calendar-form"
                layout="horizontal"
                hide-btn
                :label-col="{span: 6}"
                :wrapper-col="{span: 18}"
                :items="items"
                :entity="form"
                @confirm="confirmUpdate"></form-area>
        </a-modal>
    </div>
</template>

<script>
    import calendar from "../components/calendar/calendar";
    import { Empty } from 'ant-design-vue';
    import {clone, getDate, getKeyTitle, isArray} from "../common/js/tool";
    import {schItems, calendarItems, remindOptions, repeatOptions} from "../common/constant/sch";
    const colors = ["#FF5E42", "#FF4272", "#FF8E33", "#FACC14"]

    export default {
        name: "Calendar",
        components: {
            calendar
        },
        data() {
            return {
                list: null,
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
                key: 1,
                visible: false,
                form: {color: '#ff1e02'},
                select: null,
                loading: false,
                type: 'sch',
                schMap: {},
                calendar: null
            }
        },
        computed: {
            items() {
                let type = this.type;
                if(type == 'sch') {
                    let temp = clone(schItems);
                    temp[0].props.options = this.list ? this.list.map(item => {return {key: item.cal_id, title: item.summary}}) : [];
                    return temp
                } else if(type == 'calendar') {
                    return clone(calendarItems)
                } else {
                    return  [];
                }
            },
            title() {
                let type = this.type;
                if(type == 'sch') {
                    return '日程'
                } else if(type == 'calendar') {
                    return '日历'
                } else {
                    return  ''
                }
            }
        },
        created() {
            this.getCalendar();
        },
        methods: {
            showDetail(c) {
                this.calendar = c;
            },
            handleClick(e) {
                this.key = e;
            },
            getCalendar() {
                let url = '/common/get-calendar';
                this.loading = true;
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    this.loading = false;
                    if(res.error == 0) {
                        let list = res.data;
                        this.setSchMap(list)
                        this.list = list;
                    } else {
                        this.$message.warning(res.msg);
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
            dateChange(date) {
                this.select = date;
            },
            addSch(){
                if(this.list && this.list.length > 0) {
                    this.form = {};
                    this.type = 'sch';
                    this.visible = true;
                } else {
                    this.$confirm({
                        title: '提示',
                        content: '尚未创建日历，请先创建日历',
                        okText: '去创建',
                        onOk: () => {
                            this.key = 2;
                        }
                    });
                }
            },
            setSchMap(list) {
                let schMap = {};
                list.forEach((item) => {
                    if(item.original_ids) {
                        let original_ids = JSON.parse(item.original_ids);
                        let userList = [];
                        if(isArray(original_ids)) {
                            userList = original_ids;
                        } else {
                            if(original_ids.dept && original_ids.dept.length > 0) {
                                userList.push(...original_ids.dept.map(id => { return {id,type: 'department'} }))
                            }
                            if(original_ids.uids && original_ids.uids.length > 0) {
                                userList.push(...original_ids.uids.map(id => { return {id,type: 'user'} }))
                            }
                        }
                        item.userList = userList;
                    } else if(item.shares) {
                        item.userList = item.shares.map(item => {
                            return {id: item.userid, type: 'user'}
                        });
                    } else {
                        item.userList = [];
                    }
                    if(item.schedule && item.schedule.length > 0) {
                        item.schedule = item.schedule.filter(s => s.status == 0);
                        item.schedule.forEach((sch, i) => {
                            this.dealSch(sch, colors[i % (colors.length - 1)]);
                            let temp = {
                                calendar: {color: item.color},
                                ...sch
                            }
                            this.dealSchMap(temp, schMap)
                        });
                    }
                })
                this.schMap = schMap;
            },
            dealSchMap(item, map) {
                if(item.status == 0) {
                    if(item.startDate != item.endDate) {
                        if(item.endTime < item.startTime) return;// 防止数据错误出现死循环
                        let start = new Date(item.startTime);
                        while (start.pattern("yyyy-MM-dd") != item.endDate) {
                            this.setSchMapDate(map, start.pattern("yyyy-MM-dd"), item);
                            start.setDate(start.getDate() + 1);
                        }
                    } else {
                        this.setSchMapDate(map, item.startDate, item);
                    }
                }
            },
            setSchMapDate(map, date, sch) {
                if(map[date]) {
                    map[date].push(sch);
                } else {
                    map[date] = [sch];
                }
            },
            dealSch(sch, color) {
                let start = new Date(sch.start_time * 1000);
                let end = new Date(sch.end_time * 1000)
                sch.startTime = start.getTime();
                sch.endTime = end.getTime();
                sch.startDate = start.pattern("yyyy-MM-dd");
                sch.endDate = end.pattern("yyyy-MM-dd");
                sch.start = start.pattern("HH:mm");
                sch.end = end.pattern("HH:mm");
                sch.date = sch.startDate;
                sch.showColor = color;
                let reminders = sch.reminders;
                if(reminders.is_remind == 1) {
                    sch.remind = reminders.remind_before_event_secs;
                } else {
                    sch.remind = -1;
                }
                sch.remindText = getKeyTitle(remindOptions, sch.remind);
                if(reminders.is_repeat == 1) {
                    sch.repeat = reminders.repeat_type;
                } else {
                    sch.repeat = -1;
                }
                sch.repeatText = getKeyTitle(repeatOptions, sch.repeat);
                if(sch.repeat == 1) {
                    sch.repeatText += start.pattern("E");
                } else if(sch.repeat == 2) {
                    sch.repeatText += start.pattern("d日");
                } else if(sch.repeat == 3) {
                    sch.repeatText += start.pattern("M月d日");
                }
            },
            editSch(sch) {
                this.type = 'sch';
                let form = clone(sch);
                form.time = [new Date(form.start_time * 1000).pattern("yyyy-MM-dd HH:mm"), new Date(form.end_time * 1000).pattern("yyyy-MM-dd HH:mm")]
                this.form = form;
                this.visible = true;
            },
            delSch(sch) {
                this.$confirm({
                    title: '提示',
                    content: '确定取消此日程吗？',
                    onOk: () => {
                        let url = '/common/delete-schedule';
                        this.$axios({
                            url,
                            method: 'POST',
                            noTempleFilter: true,
                            data: JSON.stringify(sch.schedule_id)
                        }).then(res => {
                            if(res.error == 0) {
                                this.$message.success('已取消');
                                this.getSchList(sch.cal_id);
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                });
            },
            editCalendar(c) {
                this.type = 'calendar';
                let form = clone(c);
                this.form = form;
                this.visible = true;
            },
            delCalendar(c) {
                this.$confirm({
                    title: '提示',
                    content: '确定删除此日历吗？',
                    onOk: () => {
                        let url = '/common/delete-calendar';
                        this.$axios({
                            url,
                            method: 'POST',
                            noTempleFilter: true,
                            data: JSON.stringify(c.cal_id)
                        }).then(res => {
                            if(res.error == 0) {
                                this.$message.success('已删除');
                                this.getCalendar();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                });
            },
            addCalendar() {
                this.type = 'calendar';
                this.form = {color: '#ff1e02'};
                this.visible = true;
            },
            addConfirm() {
                this.$refs.calendarForm.handleConfirm();
            },
            updateCalendar(form) {
                form.organizer = String(form.organizer);
                if(form.userList) {
                    let shares = {
                        uids: [],
                        dept: [],
                        tag: []
                    };
                    form.userList.forEach(item => {
                        if(item.type == "department") {
                            shares.dept.push(item.id);
                        } else if(item.type == "user"){
                            shares.uids.push(item.id);
                        } else if(item.type == "tag") {
                            shares.tag.push(item.id);
                        }
                    });
                    form.original_ids = JSON.stringify(form.userList);
                    form.shares = shares;
                }
                form.readonly = 1;
                this.$axios({
                    url: form.cal_id ? "/common/update-calendar" : "/common/add-calendar",
                    method: "POST",
                    noTempleFilter: true,
                    data: form
                }).then(res => {
                    if(res.error == 0) {
                        this.$message.success(form.cal_id ? "更新成功" : "添加成功");
                        this.visible = false;
                        this.getCalendar();
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            updateSch(form) {
                let calendar = this.list.find(item => item.cal_id == form.cal_id);
                let start = form.time[0];
                let end = form.time[1];
                form.start_time = Math.round(getDate(start).getTime() / 1000);
                form.end_time = Math.round(getDate(end).getTime() / 1000);
                form.organizer = calendar.organizer;
                let reminders = {};
                if(form.remind == -1) {
                    reminders.is_remind = 0
                } else {
                    reminders.is_remind = 1;
                    reminders.remind_before_event_secs = form.remind;
                }
                if(form.repeat == -1) {
                    reminders.is_repeat = 0
                } else {
                    reminders.is_repeat = 1;
                    reminders.repeat_type = form.repeat;
                }
                form.reminders = reminders;
                form.attendees = calendar.shares.map(u => {
                    return {
                        userid: u.userid,
                    }
                });
                this.$axios({
                    url: form.schedule_id ? "/common/update-schedule" : "/common/add-schedule",
                    method: "POST",
                    noTempleFilter: true,
                    data: form
                }).then(res => {
                    if(res.error == 0) {
                        this.$message.success(form.schedule_id ? "更新成功" : "添加成功");
                        this.visible = false;
                        this.getSchList(calendar.cal_id);
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            getSchList(cal_id) {
                this.$axios({
                    url: '/common/get-calendar-detail',
                    method: 'POST',
                    noTempleFilter: true,
                    data: JSON.stringify(cal_id)
                }).then(res => {
                    if(res.error == 0) {
                        let list = this.list;
                        let c = list.find(item => item.cal_id == cal_id);
                        c.schedule = res.data.schedule;
                        this.setSchMap(list);
                        this.list = [...list];
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            confirmUpdate(form) {
                let type = this.type;
                if(type == 'calendar') {
                    this.updateCalendar(form)
                } else if(type == 'sch') {
                    this.updateSch(form)
                }
            }
        }
    }
</script>

<style lang="less">
    .temple-calendar {
        .ant-tabs-content,
        .ant-tabs-tabpane,
        .tab-pane-box {
            height: 100%;
        }
        .ant-tabs-left-content > .ant-tabs-tabpane-inactive {
            height: 0;
        }
        .tab-pane-box {
            position: relative;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .calendar-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,.6);
        cursor: pointer;
    }
    .calendar-detail {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: @component-background;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .calendar-detail-info {
        margin-top: 16px;
        font-size: 14px;
        .calendar-desc {
            margin-top: 6px;
            line-height: 1.5;
        }
    }
    .calendar-detail-title {
        display: flex;
        align-items: flex-end;
        .calendar-shares {
            margin: 0 0 0 6px;
        }
    }
    .calendar-detail-name {
        font-weight: 600;
        font-size: 16px;
    }
    .calendar-sch {
        margin-top: 16px;
        padding-top: 16px;
        border-top: var(--border);
    }
    .calendar-sch-title {
        margin-bottom: 16px;
        font-weight: 600;
    }
    .sch-add {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }
    .calendar-top {
        position: relative;
        padding-bottom: 16px;
        border-bottom: var(--border);
    }
    .calendar-top-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
    }
    .calendar-top-back {
        position: absolute;
        top: 0;
        left: 0;
    }
    .calendar-top-btn {
        position: absolute;
        top: 0;
        right: 0;
    }
    .calendar-list {
        margin: 0 -8px;
    }
    .calendar-item {
        display: inline-block;
        padding: 8px;
        width: 25%;
    }
    .calendar-ctx {
        border-radius: 4px;
        border: var(--border);
    }
    .calendar-name {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: var(--border);
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    .calendar-info {
        padding: 8px 12px;
        font-size: 12px;
    }
    .calendar-desc {
        color: @text-color-secondary;
    }
    .calendar-shares {
        display: flex;
        align-items: center;
        margin-top: 6px;
        color: @text-color-secondary;
    }
    .calendar-share-list {
        flex: 1;
    }
    .share-title {
        display: inline-block;
    }
    .share-user-name {
        display: inline-block;
        margin-left: 6px;
        color: @primary-color;
    }
    .calendar-operate {
        display: flex;
        border-top: var(--border);
    }
    .calendar-btn {
        flex: 1;
        padding: 6px 12px;
        text-align: center;
        cursor: pointer;
        &:hover {
            background-color: @background-color-light;
        }
    }
    .calendar-del {
        border-left: var(--border);
        color: @error-color;
    }
    .date-sch-item {
        padding: 0 6px;
        color: #fff;
        cursor: pointer;
        line-height: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sch-ctx {
        min-width: 180px;
        .calendar-info {
            padding: 0 0 16px 0;
        }
    }
    .sch-name {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
    }
</style>
