<template>
    <div class="content md flex-box org">
        <address-book
            class="org-tree"
            ref="orgTree"
            type="book"
            tag
            editable
            hide-user
            :select="select"
            @select="handleSelect"
            @change="handleChange"
            v-show="orgType == 1"></address-book>
        <div class="flex-grow flex-box vertical org-info">
            <div class="flex-box align-center org-info-top" v-show="entity">
                <a-button icon="left" style="margin-right: 20px;" @click="hidePerson" v-show="selectDept && entityType == 2">返回</a-button>
                <div class="flex-grow org-top-title">
                    <open-data type="departmentName" :openid="entity.id" v-if="entityType == 1"/>
                    <open-data type="userName" :openid="entity.userid" v-else-if="entityType == 2"/>
                    <span v-else-if="entityType == 3">{{entity.name}}</span>
                </div>
                <a-space>
                    <template v-if="entityType == 3">
                        <a-button type="danger" @click="delTag">删除标签</a-button>
                        <a-button type="primary" @click="editTag">编辑标签</a-button>
                        <a-button @click="addTagBook">设置部门/成员</a-button>
                    </template>
                </a-space>
            </div>
            <div class="flex-grow flex-box vertical org-info-ctx">
                <div class="info-detail">
                    <a-table
                            v-show="entityType == 3"
                            ref="dataTable"
                            :row-key="record => record.id"
                            :columns="tagColumns"
                            :data-source="entity ? entity.ids : []"
                            :pagination="pagination"
                            @change="handleTableChange">
                        <template v-slot:name="text, record">
                            <div class="book-item">
                                <a-icon class="book-icon" :type="record.type == 'user' ? 'user' : 'folder'" />
                                <open-data class="book-name" :type="record.type == 'user' ? 'userName' : 'departmentName'" :openid="record.id" />
                            </div>
                        </template>
                        <template v-slot:action="text, record">
                            <a class="txt-btn" @click.stop="removeTagBook(record)">移除</a>
                        </template>
                    </a-table>
                    <a-descriptions class="org-detail" title="成员详情" :column="1" v-if="entityType == 2">
                        <a-descriptions-item label="姓名/法名">
                            <open-data type="userName" :openid="entity.userid" />
                        </a-descriptions-item>
                        <a-descriptions-item label="部门">
                            <a-space>
                                <open-data type="departmentName" :openid="d" v-for="d in entity.department" :key="d"/>
                            </a-space>
                        </a-descriptions-item>
                        <a-descriptions-item label="标签">
                            <a-space v-if="entity.tag && entity.tag.length > 0">
                                <span class="org-detail-tag" v-for="t in entity.tag" :key="t.id">{{t.name}}</span>
                            </a-space>
                            <div class="txt-btn" v-else>无</div>
                        </a-descriptions-item>
                    </a-descriptions>
                    <a-table
                        v-show="entityType == 1"
                        ref="dataTable"
                        :row-key="record => record.userid"
                        :columns="columns"
                        :data-source="personList"
                        :loading="loading"
                        :pagination="pagination"
                        @change="handleTableChange">
                        <template v-slot:department="text">
                            <a-space>
                                <open-data type="departmentName" :openid="d" v-for="d in text" :key="d"/>
                            </a-space>
                        </template>
                        <template v-slot:user="text, record">
                            <span v-if="record.name">{{record.name}}</span>
                            <open-data type="userName" :openid="text" v-else/>
                        </template>
                        <template v-slot:action="text, record, index">
                            <a-space class="row-btn">
                                <a class="txt-btn" @click.stop="showPerson(record, index)">查看</a>
                            </a-space>
                        </template>
                    </a-table>
                </div>
            </div>
        </div>
        <a-modal
            title="选择部门"
            :maskClosable="false"
            :closable="false"
            :width="600"
            :body-style="{padding: 0}"
            @ok="confirm"
            v-model="visible">
            <form-area
                ref="orgForm"
                class="flex-grow flex-box vertical org-form"
                layout="horizontal"
                hide-btn
                :label-col="{span: 4}"
                :wrapper-col="{span: 20}"
                :items="items"
                :entity="form" v-show="type < 3"></form-area>
            <dept-picker
                ref="bookPicker"
                multiple
                type="all"
                :select="entity && entity.ids ? entity.ids : []"
                @set="handleDeptSelect"
                @select="handleDeptSelect"
                v-show="type == 3"/>
        </a-modal>
    </div>
</template>

<script>
    import addressBook from "../components/address-book";
    import deptPicker from "../components/dept-picker";
    import {clone} from "../common/js/tool";
    import {tagItems, personItems} from "../common/constant/org";

    export default {
        name: "Organization",
        components: {
            addressBook,
            deptPicker
        },
        data() {
            return {
                type: null, // 1 编辑标签 2编辑成员标签  3选择部门/成员
                form: {},
                edit: false,
                entity: null, // 选中的对象
                entityType: null, // 选中的对象类型 1部门 2成员 3 标签
                selectDept: null,
                selectBook: null,
                visible: false,
                orgType: 1,
                personList: [],
                searchLoading: false,
                loading: false,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                columns: [
                    {title: '法名/姓名', dataIndex: 'userid', scopedSlots: { customRender: 'user'}},
                    {title: '部门', dataIndex: 'department', scopedSlots: { customRender: 'department'}},
                    {title: '操作', key: 'action', scopedSlots: { customRender: 'action'}}
                ],
                tagColumns: [
                    {title: '名称', dataIndex: 'id', scopedSlots: { customRender: 'name'}},
                    {title: '操作', key: 'action', scopedSlots: { customRender: 'action'}}
                ]
            }
        },
        computed:{
            select() {
                let res = [];
                if(this.entity) {
                    if(this.entityType == 1) {
                        res = [{
                            id: this.entity.id,
                            type: 'department'
                        }]
                    } else if(this.entityType == 3) {
                        res = [{
                            ...this.entity,
                            type: 'tag'
                        }]
                    }
                }
                return res;
            },
            orgTree() {
                return this.$refs.orgTree;
            },
            items() {
                let type = this.type;
                if(type == 1) {
                    return clone(tagItems);
                } else if(type == 2) {
                    return  clone(personItems);
                } else {
                    return  [];
                }
            }
        },
        methods: {
            handleChange({ type, operate, item}) {
                if(type == this.entityType) {
                    switch (operate) {
                        case "update":
                            this.handleSelect([{ ...item }]);
                            break;
                        case "delete":
                            this.cancelSelect();
                            break;
                    }
                }
            },
            delTag() {
                this.orgTree.deleteTag(this.entity);
            },
            editTag() {
                this.orgTree.showEditTag(this.entity);
            },
            removeTagBook(book) {
                let ids = clone(this.entity.ids).filter(item => !(item.type == book.type && item.id == book.id));
                this.$refs.orgTree.updateTag({
                    ids,
                    id: this.entity.id
                });
            },
            addBookToTag() {
                let ids = this.selectBook ? this.selectBook.map(item => {
                    return {
                        id: item.userid || item.id,
                        type: item.userid ? 'user' : 'department'
                    }
                }) : [];
                let entity = this.entity;
                this.$refs.orgTree.updateTag({
                    ids,
                    id: entity.id
                }).then(() => this.visible = false);
            },
            addTagBook() {
                const entity = this.entity;
                this.selectBook = entity && entity.ids ? [...entity.ids] : [];
                this.type = 3;
                this.visible = true;
                // this.$nextTick(() => {
                //     this.$refs.bookPicker.setSelect();
                // })
            },
            addTag() {
                this.type = 1;
                this.form = {};
                this.showModal();
            },
            handleDeptSelect(list) {
                this.selectBook = list;
            },
            showPerson(person) {
                this.entity = person;
                this.entityType = 2;
            },
            hidePerson() {
                this.entity = this.selectDept;
                this.entityType = 1;
            },
            hideModal() {
                this.visible = false;
            },
            showModal() {
                this.visible = true;
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
            },
            confirm() {
                if(this.type == 3) {
                    this.addBookToTag();
                } else {
                    this.$refs.orgForm.handleConfirm().then(form => {
                        let type = this.type;
                        if(type == 1) {
                            this.updateTag(form)
                        } else if(type == 2) {
                            this.updatePerson(form)
                        }
                    })
                }
            },
            updateTag(form) {
                let url = '/admin/tag';
                let method = 'POST';
                if(form.id) {
                    url += '/' + form.id;
                    method = 'PATCH';
                }
                if(form.ids){
                    let real_ids = {
                        uids: [],
                        dept: []
                    };
                    form.ids.forEach(item => {
                        if(item.type == "department") {
                            real_ids.dept.push(item.id);
                        } else {
                            real_ids.uids.push(item.id);
                        }
                    });
                    form.real_ids = real_ids;
                    form.visible_ids = JSON.stringify(form.ids);
                }
                this.$axios({
                    url,
                    method,
                    data: form
                }).then(() => {
                    this.$message.success(form.id ? '更新成功' : '添加成功');
                    if(this.entity && this.entityType == 3) {
                        this.$set(this.entity, 'ids', form.ids);
                    }
                    this.orgTree.getTagList();
                    this.visible = false;
                });
            },
            updatePerson() {

            },
            getDeptPerson() {
                let dept = this.entity;
                this.loading = true;
                let url = `/admin/common/get-dept-user?dept=${dept.id}`;
                this.$axios(url).then(res => {
                    if(res.error == 0) {
                        this.personList = res.data;
                        this.$set(this.pagination, 'total', res.data.length);
                    } else {
                        this.$message.warning(res.msg);
                    }
                   this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },
            handleSelect(list) {
                if(list) {
                    let item = list[0];
                    this.entity = item;
                    // 选择成员
                    if(item.userid) {
                        this.entityType = 2;
                        if(!item.department) {
                            this.getUserDetail()
                        }
                    } else if(item.tag_id) {
                        // 选择标签
                        if(!item.ids) {
                            let ids = item.real_ids.split(",");
                            this.$set(this.entity, 'ids', ids);
                        }
                        this.$set(this.pagination, 'total', this.entity.ids.length);
                        this.entityType = 3;
                    } else {
                        // 选择部门
                        this.$set(this.pagination, 'current', 1);
                        this.getDeptPerson();
                        this.selectDept = item;
                        this.entityType = 1;
                    }
                } else {
                    this.cancelSelect();
                }
            },
            cancelSelect() {
                this.entity = null;
                this.entityType = null;
                this.selectDept = null;
            },
            getUserDetail() {
                let item = this.entity;
                let url = `/admin/common/get-user?id=${item.userid}`;
                this.$axios(url).then(res => {
                    if(res.error == 0) {
                        this.$set(this.entity, 'department', res.data.department);
                        this.$set(this.entity, 'tag', res.data.tag);
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .org {
        padding: 0;
    }
    .org-tree {
        width: 300px;
        border-right: var(--border);
    }
    .org-detail {
        margin-top: 10px;
    }
    .org-detail-tag {
        color: @primary-color;
        cursor: pointer;
    }
    .org-info {
        padding: 16px;
    }
    .org-info-top {
        padding-bottom: 16px;
        border-bottom: var(--border);
    }
    .org-top-title {
        font-size: 16px;
        line-height: 32px;
    }
    .row-btn .txt-btn:not(:first-child) {
        margin-left: 8px;
    }
    .org-info-ctx {
        min-height: 0;
        overflow: auto;
    }
</style>
